/*custom font*/
@import url(https://fonts.googleapis.com/css?family=Montserrat);

/*basic reset*/
* {
    margin: 0;
    padding: 0;
}

.row{
    width:100%
}

html {
    height: 100%;
    width: 100%;
    background: #fff; /* fallback for old browsers */
}

body {
    font-family: montserrat, arial, verdana;
    background: transparent;
}

h1{
  font-family: "Poppins";
  color: #000000;
  width: 92%;
  font-weight: 400;
  line-height: 9vw;
  text-align: left;
  font-size: 3.25vh;
  margin-left: 7%;
}

h4{
    margin-top: 52vw;
    font-family: "Poppins";
    color: #000000;
    font-weight: 700;
    line-height:auto;
    font-size: 15vw;
    text-align: center;
}

h5{
    font-family: "Poppins";
    color: #000000;
    font-weight: 200;
    line-height: auto;
    text-align: left;
    font-size: 9vw;
    padding-left: 13vw;
    padding-right: 13vw;
}

h6{
    font-family: "Poppins";
    color: #000000;
    font-weight: 600;
    font-size: 6vw;
    text-align: left;
    padding-left: 13vw;
    padding-right: 13vw;
}

/*form styles*/
#msform {
    margin-top: 45vw;
    text-align: center;
    width: 100%;
    margin-left: 4%;
}


#msform fieldset {
    border: 0 none;
    border-radius: 20px;
    padding: 20px 00px;
    box-sizing: border-box;
    width: 85%;
    margin: 0 7.5%;

    /*stacking fieldsets above each other*/
    position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
    display: none;
}

/*inputs*/
#msform input, #msform textarea {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 35px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    font-size: 3vw;
}

#msform .error-text{
    border: 1px solid red;
    color: red;
    font-weight: bold;
}

#msform input:focus, #msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #FFD300;
    outline-width: 0;
    transition: All 0.5s ease-in;
    -webkit-transition: All 0.5s ease-in;
    -moz-transition: All 0.5s ease-in;
    -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
    width: 100%;
    height: 11vw;
    background: #FFD300;
    font-weight: bold;
    color: black;
    border: 0 none;
    border-radius: 25vw;
    cursor: pointer;
    margin-right: 5%;
    margin-top: 0%;
}

#msform .action-button:hover, #msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #FFD300;
    background: #454545;
    color: white;

}

#msform .action-button-previous:hover, #msform .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}

/*headings*/
.fs-title {
    color: #000000;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.fs-subtitle {
    color: #000000;
    font-size: 15px;
    text-align: left;
    margin-bottom: 7%;
}

#msform p {
    color: #000000;
    font-family: "Poppins";
    font-size: 60%;
    font-weight: 200;
    letter-spacing: 0em;
    margin: -0.5vw 0 0.5vw 0;
    text-align: justify;
}

/*progressbar*/
#progressbar {
    margin-top: 5vw;
    margin-bottom: 1vw;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
    margin-left: 3%;
    width: 100%;
}

#progressbar li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    width: 14%;
    float: left;
    position: relative;
    letter-spacing: 1px;
}

#progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 24px;
    height: 24px;
    display: block;
    font-size: 12px;
    color: white;
    background: #070707;
    border: 2px solid none;
    border-radius: 25px;
    margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 4px;
    background: #070707;
    position: absolute;
    left: -50%;
    top: 9px;
    z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before {
    background: #FFD300;
    color: black;
}

#progressbar li.active:after{
    background: #070707;
    color: white;
}


/* Not relevant to this form */
.dme_link {
    margin-top: 30px;
    text-align: center;
}
.dme_link a {
    background: #FFF;
    font-weight: bold;
    color: #FFD300;
    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 5px 25px;
    font-size: 12px;
}

.dme_link a:hover, .dme_link a:focus {
    background: #000000;
    text-decoration: none;
}

.step {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  /* Define different styles for different steps */
  .step.step-0 {
    opacity: 1;
    transform: scale(1);
  }
  
  .step.step-1 {
    opacity: 0;
    transform: scale(0.8);
  }
  
  .step.step-2 {
    opacity: 0;
    transform: scale(0.8);
  }

  .final-buttons{
    justify-content: center;
    width:100%;
    margin-top: 5%;
  }

  .text-input-container{
    margin: 0vw;
  }

  .selection-container-type-property label{
    margin-bottom: 3%;
}

.selection-container label{
    margin-bottom: 3%;
}

@media screen and (min-width: 1001px) {

    /*headings*/
    .fs-title {
        font-size: 3.5vh;
        font-weight: 800;
        line-height: 35px;
        letter-spacing: 0rem;
        text-align: left;
    }

    .fs-subtitle {
        font-size: 3.5vh;
        text-align: left;
        margin-bottom: 4%;
    }

    .fs-title-end {
        font-size: 5.5vh;
        font-weight: 800;
        line-height: 35px;
        letter-spacing: 0rem;
        text-align: center;
    }

    /*form styles*/
    #msform {
        margin-top: 11vw;
        margin-left: 2%;
        width: 150%;
    }

    #msform p {
        font-size: 1vh;
    }

    .selection-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2%;
        margin-bottom: 1%;
    }

    .selection-container-type-property{
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin-top: 2%;
        margin-bottom: 1%;
    }
    .selection-container-type-property span{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-right: 5%;
    }

    .text-input-container{
        margin-top: 3vw;
        align-self: center;
        margin-left: 20%;
        margin-right: 25%;
    }
    
        .final-buttons{
            margin-top: 0vw;
            font-size: 1.5vw;
        }
    
    #msform .action-button {
        width: 100%;
        height: 4vw;
        border-radius: 25vw;
        margin-right: 5%;
        margin-top: 5%;
        align-items: center;
    }

    .back-button-container{
        display: flex;
        justify-content: left;
    }

    h1{
        width: 100%;
        font-size: 5.5vh;
        margin-left: 0%;
        margin-right: 0%;
        padding-right: 0%;
        padding-left: 0%;
        font-weight: 700;
        line-height: 4vw;
        text-align: center;
    }

    /*inputs*/
    #msform input, #msform textarea {
        font-size: 1.5vw;
        height: 3.5vw;
        padding-left: 3%;
    }
    
    h4{
        margin-top: 15%;
        padding-left: 0vw;
        padding-right: 0vw;
        font-size: 8vw;
    }
    
    h5{
        line-height: 3.5vw;
        font-size: 3vw;
        text-align: center;
    }
    
    h6{
        font-size: 2vw;
        text-align: center;
    }
    
    #progressbar {
        margin-top: 4vw;
        margin-bottom: 1vw;
        overflow: hidden;
        display: flex;
        justify-content: center;
        /*CSS counters to number the steps*/
        counter-reset: step;
        margin-left: 0%;
        width: 100%;
    }
    
    #progressbar li:before {
        width: 54px;
        height: 54px;
        font-size: 32px;
    }
    
    #progressbar li:after {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        left: -50%;
        top: 25px;
        z-index: -1; /*put it behind the numbers*/
    }
    
}