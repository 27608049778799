.App {
  text-align: center;
  display: grid;
  grid-template-rows: 20vw 1fr;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.fullPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 26vw;
  font-family: "Roboto", "Poppins", sans-serif;
}

.form-position{
    width:99%;
    position: absolute;
    grid-column: 1/1;
    grid-row: 2/2;
}

.footer-position {
  display: grid;
  grid-column: 1/1;
  grid-row: 3/3;
  padding-top: 140vw;
}

@media screen and (min-width: 1001px) {
  .form-position{
    width:99%;
    position: absolute;
    display: grid;
    grid-row: 2/2;
    padding-top: 0vw;
}

  .footer-position {
    display: grid;
    grid-row: 3/3;
    padding-top: 21vw;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
